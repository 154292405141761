import React from 'react'

import History from '../components/PageSection/History'
import Footer from '../components/PageSection/Footer'
import Header from '../components/PageSection/Header'
import Seo from '../components/PageSection/Seo'
import {useTranslation, Link} from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

const HistoryPage = () => {

    const {t} = useTranslation()

    return <div id="wrapper">

        <Header page={'history'} />

        <Seo />

        <div id={'content'} />

        <History />

        <div style={{position: 'relative', left: '50vw', paddingLeft: '5rem', marginBottom: '5rem'}}>
            <Link to={'/'} className={'button'}>Go back</Link>
        </div>

        <Footer page={'history'} />
    </div>
}

export default HistoryPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
