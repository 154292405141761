import React, {FunctionComponent} from 'react'
import {useTranslation} from 'gatsby-plugin-react-i18next'

import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'

const History: FunctionComponent = () => {
    const { t } = useTranslation()

    return <Section id="history">
        <SectionHeader title={t('history.title')}>
            <p>{t('history.introduction')}</p>
        </SectionHeader>
        <div dangerouslySetInnerHTML={{__html: t('history.description')}} />
    </Section>
}

export default History
